// App.tsx
import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="app">
      {/* Header Section */}
      <header className="header">
        <h1 className="title">한양대학교 고전음악회</h1>
        <p className="subtitle">클래식과 음악감상을 위한 여러분들의 공간</p>
      </header>

      {/* Main Section */}
      <main className="main">
        <p className="open-text">오픈 예정</p>
        <p className="date-text">2025년 상반기 예정</p>
        <p className="notice-text">자세한 일정은 추후 공지됩니다.</p>
      </main>

      {/* Footer Section */}
      <footer className="footer">
        <p className="footer-text">
          고전음악회 |{' '}
          <a href="mailto:cynaps21@gmail.com">관리자 (Harry Kim)</a>
        </p>
        <div className="social-links">
          <a
            href="https://www.instagram.com/hyu_goeum?igsh=eWQ1cXVuYWhyd3B5"
            className="social-link"
          >
            Instagram
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;
